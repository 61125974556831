import React, {useState, useRef} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import InquiryForm from './InquiryForm'

const InquireNowForm = () =>{

    return (
    <>
    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title fs-5" id="exampleModalLabel">
              Inquire Now
            </div>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body bg">
            <InquiryForm/>
          </div>
          </div>
        </div>
      </div>
    </>
    )
}

export default InquireNowForm;