import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/banners/Banner1.webp';
import FAQsSection from '../components/FAQsSection';
import Header from '../components/layout/Header';
import Accordion from 'react-bootstrap/Accordion';

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
            <>
        <Helmet>
            <meta charSet="utf-8" />
            <title> </title>
            <meta name="description" content="" />
            <link rel="canonical" href="https://800homes.ae/terms-and-conditions"/>
        </Helmet>
    <Header/>

    <section id='website-responsive' className="container-fluid common-banner shadow-800homes" style={{backgroundImage:`url(${BannerImg})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-lg-10">
          <h1 className="lh-sm fw-600 banner-title text-uppercase text-white text-center" data-aos="fade-up">
          Terms <span className=''> & Conditions </span>
          </h1>
          <div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>

    <section className="container-fluid">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-10 mx-auto">
          
          <div className="pb-5" data-aos="fade-up">
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            Welcome to the 800 Homes Real Estate Brokerage website! These Terms and Conditions govern your use of our website and services. By accessing or interacting with our site, you agree to adhere to these terms. Whether you're exploring property listings, making inquiries, or using our digital tools, these guidelines ensure a smooth and legally protected experience for both you and us. If you disagree with any part of these terms, we advise you to discontinue using our website. 
            </div>
          </div>

          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Website Use 
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            Our website provides you with access to property listings, real estate services, and other valuable resources to assist with your property search or sale. You may use our website solely for lawful purposes and in a manner consistent with these terms. Unauthorized use of our website, including any efforts to gain unauthorized access to our servers or systems, is strictly prohibited. 
            </div>
          </div>   
          
          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Intellectual Property 
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            All content on the 800 Homes Real Estate Brokerage website, including text, images, graphics, logos, videos, and design elements, are the intellectual property of 800 Homes or our content partners. You may not reproduce, distribute, or otherwise use any content without prior written permission. Unauthorized use may result in legal consequences. 
            </div>
          </div>   
          
          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Property Listings 
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            Our property listings are provided to offer general information about properties available for sale or rent. We strive to ensure that all listings are accurate and up-to-date; however, we cannot guarantee the absolute accuracy of the information. Prices, availability, and descriptions may change without notice, and properties listed may be withdrawn at any time without prior warning. 
            </div>
          </div>   

          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Third-Party Links 
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            For your convenience, our website may contain links to external websites managed by third parties. We do not have control over the content or operations of these third-party sites, and we are not responsible for their privacy practices, security, or functionality. If you choose to visit a linked website, you do so at your own risk, and we encourage you to review their terms and policies. 
            </div>
          </div>   

          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Limitation of Liability 
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            While we strive to provide the best possible experience, 800 Homes Real Estate Brokerage is not liable for any direct, indirect, or consequential damages arising from the use of this website, its content, or services. We offer the site and its features "as is" without any warranties or guarantees regarding functionality, accuracy, or reliability. 
            </div>
          </div>   

          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            User Responsibilities 
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            As a user, you agree to provide accurate and truthful information during your interactions with our website, especially when making inquiries or submitting forms. You must not attempt to disrupt the website’s performance or engage in any activities that could harm 800 Homes, its partners, or other users. 
            </div>
          </div>   

          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Privacy Policy 
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            We respect your privacy. To understand how we collect, store, and manage your personal information, please refer to our Privacy Policy. By using our website, you consent to the collection and use of your data as outlined in our Privacy Policy. 
            </div>
          </div>  
          
          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Indemnification 
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            You agree to indemnify, defend, and hold 800 Homes Real Estate Brokerage and its affiliates, partners, and employees harmless from any claims, liabilities, damages, or expenses (including legal fees) arising from your use of the website or breach of these Terms and Conditions. 
            </div>
            </div> 

            <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Modifications to Terms 
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            We may amend these Terms and Conditions from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify users of significant changes by updating the "Effective Date" at the top of this document. Continued use of the website after changes have been posted constitutes acceptance of the new terms. 
            </div>
          </div>   

          <div className="" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Governing Law 
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            These Terms and Conditions are governed by the laws of the United Arab Emirates. Any legal disputes arising from your use of this website shall be resolved in accordance with UAE law, with the exclusive jurisdiction of the courts in Dubai. 
            </div>
          </div>   

        </div>
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>

    <Footer/>
    </>
  );
}

export default TermsAndConditions;
