import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ProfileDesktop from '../components/faraaz/ProfileDesktop';
import ProfileMobile from '../components/faraaz/ProfileMobile';
import Priyanka from '../assets/images/Priyanka.webp';

const ProfilePriyanka = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>

    <Helmet>
    <meta name="robots" content="noindex"/>
    <meta charSet="utf-8" />
    <title> Priyanka Dev - 800 Homes </title>
    </Helmet>

    <section id="website-responsive" className="d-none d-md-block">
      <ProfileDesktop
      Image={Priyanka}
      Title="Priyanka Dev"
      Designation="Sales Director"
      PhoneNumberLink="+971585799891"
      PhoneNumber="+971 58 579 9891"
      WhatsappLink="971585799891"
      Whatsapp="+971 58 579 9891"
      Email="priyanka@800homes.ae"
      />
    </section>

    <section id="website-responsive" className="d-block d-md-none">
      <ProfileMobile
      Image={Priyanka}
      Title="Priyanka Dev"
      Designation="Sales Director"
      PhoneNumberLink="+971585799891"
      PhoneNumber="+971 58 579 9891"
      WhatsappLink="971585799891"
      Whatsapp="+971 58 579 9891"
      Email="priyanka@800homes.ae" 
      />
    </section>

    </>
  );
}

export default ProfilePriyanka;
