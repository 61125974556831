import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/banners/Banner1.webp';
import FAQsSection from '../components/FAQsSection';
import Header from '../components/layout/Header';
import Accordion from 'react-bootstrap/Accordion';

const FAQs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
            <>
        <Helmet>
            <meta charSet="utf-8" />
            <title> </title>
            <meta name="description" content="" />
            <link rel="canonical" href="https://800homes.ae/faqs"/>
        </Helmet>
    <Header/>

    <section id='website-responsive' className="container-fluid common-banner shadow-800homes" style={{backgroundImage:`url(${BannerImg})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 col-lg-10">
          <h1 className="lh-sm fw-600 banner-title text-uppercase text-white text-center" data-aos="fade-up">
            FAQs
          </h1>
          <div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>

    <section id='website-responsive' className="container-lg text-white">
    <h2 className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4' data-aos="fade-up">
      Frequently Asked Questions (FAQs)
      </h2>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-md-8">
        <Accordion defaultActiveKey={1} className='border-0' data-aos="fade-up">
            <FAQsSection
            EvenyKey={1}
            Title="What services does 800 Homes offer? "
            Para="800 Homes offers a wide range of real estate services, including buying, selling, and investment advisory. We help clients find lucrative opportunities in Dubai's vibrant property market."
            />

            <FAQsSection
            EvenyKey={2}
            Title="Is Dubai an ideal place to invest in real estate? "
            Para="Absolutely! Dubai is a global hub for business and tourism, known for its stable economy, world-class infrastructure, and tax-free environment. With its luxury lifestyle, safety, and high rental yields, it's an attractive destination for property investors. "
            />

            <FAQsSection
            EvenyKey={3}
            Title="What types of properties does 800 Homes specialize in? "
            Para="We specialize in luxury villas, apartments, and commercial spaces. Whether you're seeking a high-end residence or a prime investment opportunity, 800 Homes has something to suit your needs. "
            />

            <FAQsSection
            EvenyKey={4}
            Title="What are the benefits of buying property from 800 Homes? "
            Para="Choosing 800 Homes means benefiting from expert market knowledge, personalized service, a broad selection of properties, and secure transactions. We offer end-to-end support, exclusive deals, and post-purchase services for a seamless property-buying experience. "
            />

            <FAQsSection
            EvenyKey={5}
            Title="How does 800 Homes assist with property purchases? "
            Para="Our expert agents guide you through every step of the buying process, from identifying the perfect property to finalizing the deal. We handle negotiations, legal documentation, and provide tailored advice to ensure a smooth transaction. "
            /> 
            
            <FAQsSection
            EvenyKey={6}
            Title="What should I consider when buying a property in Dubai? "
            Para="Consider factors like location, property type, developer reputation, market trends, and legal aspects. Our team at 800 Homes provides detailed insights to help you make informed decisions. "
            />  

            <FAQsSection
            EvenyKey={7}
            Title="How do I schedule a property viewing with 800 Homes? "
            Para={<>
            Scheduling a viewing is easy! Call us at <a href='tel:80046637' className="text-decoration-none text-ss-primary"> 800-46637 </a> or visit our website to book a time. We'll arrange a convenient slot to show you properties that match your criteria. 
            </>}
            />         

          </Accordion>
          </div>      
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>

    <Footer/>
    </>
  );
}

export default FAQs;
