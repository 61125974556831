import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/banners/Banner1.webp';
import FAQsSection from '../components/FAQsSection';
import Header from '../components/layout/Header';
import Accordion from 'react-bootstrap/Accordion';
import { Phone } from 'react-bootstrap-icons';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
            <>
        <Helmet>
            <meta charSet="utf-8" />
            <title> </title>
            <meta name="description" content="" />
            <link rel="canonical" href="https://800homes.ae/privacy-policy"/>
        </Helmet>
    <Header/>

    <section id='website-responsive' className="container-fluid common-banner shadow-800homes" style={{backgroundImage:`url(${BannerImg})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 col-lg-10">
          <h1 className="lh-sm fw-600 banner-title text-uppercase text-white text-center" data-aos="fade-up">
          Privacy Policy
          </h1>
          <div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>

    <section className="container-fluid">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-10 mx-auto">
          
          <div className="pb-5" data-aos="fade-up">
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            At 800 Homes Real Estate Brokerage, we value your privacy and are committed to protecting the personal information you share with us. This policy outlines the types of data we collect, how we use it, and your rights. You agree to the terms outlined in this Privacy Policy by using our website.  
            </div>
          </div>

          <div className="pb-5" data-aos="fade-up">
            <div className="pb-4">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Information We Collect  
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            We collect certain personal information when you interact with us through our website, phone calls, or emails. This may include:  
            </div>
            </div>

            <div className="pb-4">
            <div className="pb-2 text-white fs-ss-18 lh-sm fw-300">
            Personal Identification Information:
            </div>
            <div className=" text-grey fs-ss-16 fw-300 col-12 col-md-11 lh-1-7em">
            This includes your name, phone number, email address, and mailing address. We collect this information when you submit an inquiry, register on our website, or use our services. 
            </div>
            </div>

            <div className="pb-4">
            <div className="pb-2 text-white fs-ss-18 lh-sm fw-300">
            Property Information:
            </div>
            <div className=" text-grey fs-ss-16 fw-300 col-12 col-md-11 lh-1-7em">
            We collect data related to the properties you're interested in or that you own, such as property location, preferences, and related documents.  
            </div>
            </div>

            <div className="pb-4">
            <div className="pb-2 text-white fs-ss-18 lh-sm fw-300">
            Financial Information:
            </div>
            <div className=" text-grey fs-ss-16 fw-300 col-12 col-md-11 lh-1-7em">
            We may request payment information or banking details in specific transactions, primarily to process transactions or facilitate mortgage applications. 
            </div>
            </div>

            <div className="pb-4">
            <div className="pb-2 text-white fs-ss-18 lh-sm fw-300">
            Technical Information:
            </div>
            <div className=" text-grey fs-ss-16 fw-300 col-12 col-md-11 lh-1-7em">
            When you use our website, certain information, such as your IP address, browser type, and operating system, is collected automatically via cookies and similar tracking technologies.  
            </div>
            </div>
            
          </div>   
          
          <div className="pb-5" data-aos="fade-up">

            <div className="pb-4">
            <h2 className="text-white fs-ss-22 lh-sm fw-500">
            How We Use Your Information  
            </h2>
            </div>

            <div className="pb-4">
            <div className="pb-2 text-white fs-ss-18 lh-sm fw-300">
            Facilitating Real Estate Transactions:
            </div>
            <div className=" text-grey fs-ss-16 fw-300 col-12 col-md-11 lh-1-7em">
            Your personal details and property-related information allow us to serve you better when buying, selling, or renting a property.  
            </div>
            </div>

            <div className="pb-4">
            <div className="pb-2 text-white fs-ss-18 lh-sm fw-300">
            Communications: 
            </div>
            <div className=" text-grey fs-ss-16 fw-300 col-12 col-md-11 lh-1-7em">
            We use your email address or phone number to update you about your property search, new listings, offers, and other real estate news that might interest you.
            </div>
            </div>
            <div className="pb-4">
            <div className="pb-2 text-white fs-ss-18 lh-sm fw-300">
            Marketing and Website Improvement: 
            </div>
            <div className=" text-grey fs-ss-16 fw-300 col-12 col-md-11 lh-1-7em">
            By analyzing your usage of our website, we can improve its functionality and offer more tailored property recommendations. We may also send marketing materials based on your preferences, but you can always opt-out.  
            </div>
            </div>
            <div className="pb-4">
            <div className="pb-2 text-white fs-ss-18 lh-sm fw-300">
            Legal Compliance: 
            </div>
            <div className=" text-grey fs-ss-16 fw-300 col-12 col-md-11 lh-1-7em">
            We may need to process your personal information to comply with legal or regulatory obligations. For example, we will disclose information to government bodies or law enforcement agencies if required.   
            </div>
            </div>

          </div>   
          
          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Sharing Your Information  
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            We understand the importance of keeping your information confidential. However, there are specific circumstances where we may share your data:  

            <ul className=''>
              <li className="pb-2">
              To facilitate real estate transactions, we may share your details with third parties like mortgage brokers, legal professionals, property inspectors, or maintenance services. 
              </li>
              <li className="pb-2">
              These third parties are carefully selected and must handle your information securely. 
              </li>
              <li className="pb-2">
              If we collaborate with other real estate companies, developers, or agencies to expand our offerings, we may share your data with them to provide a broader range of services.  
              </li>
              <li className="pb-2">
              In the event of a merger, acquisition, or sale of the company, your data may be transferred to the new owner or entity. We will notify you of any such change.  
              </li>
              <li className="pb-2">
              We may share your data when legally required, such as in response to a court order or government request.  
              </li>
            </ul>
            </div>
          </div>   

          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            How We Protect Your Data    
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            We take data protection seriously and have implemented a range of physical, technical, and administrative measures to protect your information. Access to personal data is restricted to authorized personnel only, and we store your information in secure systems.  
            <div className="py-2" />
            We regularly review our security practices to keep your data safe. However, no method of transmission over the Internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
            </div>
          </div>   

          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
              Use of Cookies and Tracking Technologies  
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            Cookies are small text files placed on your device when you visit our website. These help us track your preferences, improve website functionality, and provide you with personalized content. By using our website, you consent to the use of cookies.  
            <div className="py-2" />
            You can control cookie settings in your browser to manage your preferences. However, disabling cookies may affect your experience on our site.  
            </div>
          </div>   

          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Your Rights Regarding Your Data   
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            You have the following rights concerning your personal information:  
            <ul className=''>
              <li className="pb-2">
              You can request access to the data we hold about you and correct any inaccuracies.  
              </li>
              <li className="pb-2">
              You can ask us to delete your personal information, though certain legal obligations may require us to retain it for specific periods.  
              </li>
              <li className="pb-2">
              You can opt out of marketing communications by following the instructions in our emails or contacting us directly.  
              </li>
              <li className="pb-2">
              Sometimes, you can request a copy of your data in a structured, machine-readable format. 
              </li>
            </ul>
            </div>
          </div>   

          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Third-Party Links  
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            Our website may contain links to third-party websites, including property-related services or partners. Please note that we are not responsible for the privacy practices of these websites, and we encourage you to review their privacy policies.  
            </div>
          </div>   

          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Changes to This Privacy Policy  
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            We may update this Privacy Policy from time to time to reflect changes in our practices or regulatory requirements. We encourage you to review this page periodically for any updates.  
            </div>
          </div>   

          <div className="pb-5" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            Contact Us  
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
            If you have any questions or concerns regarding our Privacy Policy or how your information is handled, please contact us at:  
            </div>
          </div>   

          <div className="" data-aos="fade-up">
            <h2 className="pb-2 text-white fs-ss-22 lh-sm fw-500">
            800 Homes Real Estate Brokerage   
            </h2>
            <div className=" text-grey fs-ss-18 fw-300 col-12 col-md-11 lh-1-7em">
              
              <div className="pb-2">
              <span className='fw-400 text-ss-primary'> Email:  </span>
              <a className='text-decoration-none text-white text-hover-ss-primary fw-300' href="mailto:inquiry@800homes.ae" target='_blank'> inquiry@800homes.ae </a> 
              </div>

              <div className="">
              <span className='fw-400 text-ss-primary'> Phone: </span>
              <a className='text-decoration-none text-white text-hover-ss-primary fw-300' href="tel:+971585746637" target='_blank'> +971-58-574-6637 </a> 
              </div>
            </div>
          </div>   
        </div>
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>

    <Footer/>
    </>
  );
}

export default PrivacyPolicy;
